// src/components/Footer.js
import React from 'react';

function Footer() {
  return (
    <div className="Footer-section">
      <p></p>
    </div>
  );
}

export default Footer;