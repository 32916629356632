// src/components/Header.js
import React from 'react';

function Header() {
  return (
    <div className="Header-section">
      <p> </p>
    </div>
  );
}

export default Header;