// src/components/Services.js
import React from 'react';

function Services() {
  return (
    <div className="Services-section">
      <p> </p>
    </div>
  );
}

export default Services;