// src/components/About.js
import React from 'react';

function About() {
  return (
    <div className="about-section">
      <h2>About Me</h2>
      <p>Delivering fit for purpose information products for actionable (data driven) insights.</p>
    </div>
  );
}

export default About;