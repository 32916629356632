// src/components/Contact.js
import React from 'react';

function Contact() {
  return (
    <div className="Contact-section">
      <h2>Contact Me</h2>
      <p>mark@datashape.nl</p>
    </div>
  );
}

export default Contact;